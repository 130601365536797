import { Controller, useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { dialogActions } from 'src/old-app/store/dialogSlice'
import { showMessage } from 'src/old-app/store/fuse/messageSlice'
import { interfaceActions } from 'src/old-app/store/interfaceSlice'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import DialogFooter from '../DialogFooter'
import DialogHeader from '../DialogHeader'
import DialogMain from '../DialogMain'
import { getTask, getTimeSpentButtonId } from 'src/old-app/store/tasksSlice'
import { useCreateTimeTrackButtonMutation, useUpdateTimeTrackButtonMutation } from 'src/old-app/service/Tasks'
import ErrorText from '../../ui/ErrorText'
import { useTranslation } from 'react-i18next'
import DateTimePickerInput from 'src/shared/components/date-time-picker-input/DateTimePickerInput'
import dayjs from 'dayjs'

const SpentTimeButton = () => {
	const { t } = useTranslation()
	const schema = yup.object().shape({
		stop: yup.date().required(t('working_finish_time_is_required')).typeError(t('invalid_date')),
	})
	const dispatch = useDispatch()
	const task = useSelector(getTask)
	const timeSpentButtonId = useSelector(getTimeSpentButtonId)

	const [createTimeTrackButton] = useCreateTimeTrackButtonMutation()
	const [updateTimeTrackButton] = useUpdateTimeTrackButtonMutation()

	const {
		handleSubmit,
		control,
		formState: { errors },
		watch,
		setError,
		setValue,
	} = useForm({
		defaultValues: {},
		resolver: yupResolver(schema),
	})

	const createProjectHandler = async (data) => {
		dispatch(interfaceActions.setIsLoading(true))
		const stop = dayjs(data?.stop).toISOString()

		if (!timeSpentButtonId) {
			await createTimeTrackButton({
				task_id: task.id,
				stop: data?.stop ? stop : undefined,
			})
		} else {
			const trackingButton = task?.time_tracking_by_button?.find((item) => item?.id === timeSpentButtonId)

			const startTime = dayjs(trackingButton?.start).toISOString()

			if (dayjs(stop).isBefore(startTime)) {
				dispatch(interfaceActions.setIsLoading(false))
				return setError('stop', { message: t('finish_time_less_start_time') })
			}

			await updateTimeTrackButton({
				id: timeSpentButtonId,
				body: {
					stop: data?.stop ? stop : undefined,
				},
			})
		}

		dispatch(dialogActions.setDialog(false))
		dispatch(
			showMessage({
				message: t('spent_time_indicated'),
				autoHideDuratigon: 1000,
				anchorOrigin: {
					vertical: 'top',
					horizontal: 'top-right',
				},
				variant: 'success',
			})
		)
		dispatch(interfaceActions.setIsLoading(false))
	}

	return (
		<form onSubmit={handleSubmit(createProjectHandler)} className="tt-w-600">
			<DialogHeader title={t('time_spent')} />
			<DialogMain>
				<div className="tt-flex tt-flex-col tt-mb-10">
					<div className="tt-flex tt-gap-8 tt-items-center">
						<div className="tt-flex tt-flex-col  tt-gap-4 tt-w-full">
							<span className="tt-text-14 tt-mb-4">{t('finish_time')}</span>
							<Controller
								render={({ field: { value, onChange } }) => <DateTimePickerInput value={value} onChange={onChange} />}
								name="stop"
								control={control}
							/>
							<ErrorText errors={errors} field="stop" />
						</div>
					</div>
				</div>
			</DialogMain>
			<DialogFooter isDisabled={false} rightLabel={t('save')} />
		</form>
	)
}

export default SpentTimeButton
